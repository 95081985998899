@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&display=swap');
@font-face {
  font-family: 'Roboto Slab', serif;
  src:
    local('Roboto Slab', serif),
    url(../fonts/Roboto-Light.woff) format('woff');
}
@font-face {
  font-family: 'ProximaNova';
  src:
    local('ProximaNova'),
    url(../fonts/ProximaNova-Regular.woff) format('woff');
}
@font-face {
  font-family: 'IBM Plex Sans';
  font-feature-settings:
    'liga' off,
    'clig' off;
  src:
    url(../fonts/IBMPlexSans-Regular.ttf) format('truetype'),
    //  url(../fonts/IBMPlexSans-Light.ttf) format('truetype'),
    url(../fonts/IBMPlexSans-Medium.ttf) format('truetype'),
    url(../fonts/IBMPlexSans-Bold.ttf) format('truetype');
}
body {
  height: 100% !important;
  font-family: 'Roboto Slab', serif !important;
  margin: 0 !important;
  background-color: #eaeef2;
  ::-webkit-scrollbar {
    display: none;
  }
}

// main {
//   display: block;
//   height: 100%;
// }

.custom-cell {
  display: flex;
  align-items: center;
}
/* Center align cell content */
.ag-theme-alpine .ag-cell {
  border-right: 1px solid #ccc !important; /* Add right border */
  border-bottom: 1px solid #ccc !important; /* Add bottom border */
  padding: 10px; /* Add padding */
  text-align: center; /* Center align text */
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Vertically center content */
  justify-content: center; /* Horizontally center content */
}

/* Center align header cell content */
.ag-theme-alpine .ag-header-cell {
  border-right: 1px solid #ccc; /* Add right border */
  border-bottom: 1px solid #ccc; /* Add bottom border */
  text-align: center; /* Center align text */
  display: flex; /* Use flexbox for alignment */
  align-items: center; /* Vertically center content */
  justify-content: center; /* Horizontally center content */
}

/* Optional: Style row borders */
.ag-theme-alpine .ag-row {
  border: none; /* Remove default row borders */
}
